<template>
  <section class="password-container">
    <div class="form-view">
      <p class="title">设置新密码</p>
      <div class="dialog-content">
        <el-form :model="form" :rules="rules" ref="passForm" label-width="150px">
          <el-form-item label="手机号:" prop="cellPhone">
            <el-input placeholder="请输入" v-model="form.cellPhone"></el-input>
          </el-form-item>
          <el-form-item label="用户名:" prop="userName">
            <el-input placeholder="请输入" v-model="form.userName"></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPassword">
            <el-input type="password" v-model="form.newPassword" placeholder="密码长度6-11位"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="confirm">
            <el-input type="password" v-model="form.confirm" placeholder="请再次输入密码"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="footer">
          <el-button type="primary" @click="submit" :loading="submitLoading">确定</el-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data(){
      //校验新密码
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if(value.length < 6 || value.length > 11){
        callback(new Error('密码长度为6-11位'));
      }else {
        if (this.form.confirm !== '') {
          this.$refs.passForm.validateField('confirm');
        }
        callback();
      }
    };
    //校验确认密码
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    // 校验手机号
    const checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      if (regMobile.test(value)) {
        return cb() // 校验通过
      } else {
        return cb(new Error('请输入合法的手机号'))
      }
    };
    return {
      verifyCodeUrl:'',//图片路径
      timeMore:120,//短信按钮倒计时//默认120秒
      submitLoading:false,//保存按钮loading
      form:{
        cellPhone: '',//手机号码
        userName:'',//用户名
        newPassword:'',//密码
        confirm:'',//确认密码
      },
      rules:{// 表单校验规则对象
        cellPhone: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        newPassword: [
          {required: true,validator:validatePass,trigger:'blur' }
        ],
        confirm: [
          {required:true,validator:validatePass2,trigger:'blur'}
        ],
      }
    }
  },
  head: {
    title: '设置新密码'
  },
  mounted(){

  },
  methods:{
      //重置密码
    submit(){
      this.$refs.passForm.validate( async (valid) => {
        if (valid) {
          this.submitLoading = true;
          console.log('修改密码要提交的表单')
          console.log(this.form)
          /*const { data: res } = await this.$http.put('/user/resetPassword', this.form)
          this.loading = false;
          if (res.code !== 200) {
            this.submitLoading = false;
            this.$message.error(res.message);
          } else {
            this.submitLoading = false;
            this.$message.success(res.message);
           await this.$router.push({path:'/'});
          }*/
        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style scoped>
.password-container{
  display:flex;
  flex-direction:column;
  height:100%;
  align-items:center;
  justify-content:center;
}
.form-view{
  padding:36px 24px;
  width:592px;
}
.form-view .title{
  font-size:24px;
  color:#262626;
  text-align:center;
  margin-bottom:24px;
}
.form-view .code_btn{
  width:200px;
}
.form-view .code_img{
  width:112px;
  height:40px;
  margin-left:10px;
}
.form-view .item-view{
  display:flex;
  align-items:center;
}
.form-view .sms_btn{width:200px;margin-right:10px;}
.footer{text-align:center;}
.footer .el-button{width:30%;margin-top:16px;}
</style>
